// add prices by converting them to cents and then back to unit
export function addPrices(...prices: Array<number | undefined | null>): number {
  if (prices.length === 0)
    return 0

  const first = prices.shift()
  let result = (first ?? 0) * 100

  for (const price of prices) {
    if (price) {
      result += price * 100
    }
  }

  return Math.round(result) / 100
}

// substract prices by converting them to cents and then back to unit
export function substractPrices(
  ...prices: Array<number | undefined | null>
): number {
  if (prices.length === 0)
    return 0

  const first = prices.shift()
  let result = (first ?? 0) * 100

  for (const price of prices) {
    if (price) {
      result -= price * 100
    }
  }

  return Math.round(result) / 100
}

// round price to keep only 2 decimals
export function roundPrice(
  price: number | undefined | null,
): number {
  if (price === undefined || price === null || Number.isNaN(price)) {
    return 0
  }

  return Math.round(price * 100) / 100
}
